/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { appConfig } from '../../../../../constants/index';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import s from './style/DownloadDropdown.module.scss';
import CheckCircleComponent from '../../../assets/CheckCircleComponent';
import DownloadIcon from '../../../assets/DownloadIcon';
import downloadFileFunc from '../../../../utils/functions/downloadFileFunc';

const useStyles = makeStyles({
  cancelButton: {
    background: '#2B39B9!important',
    border: 'none',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#FFFFFF!important',
  },
  submitButton: {
    '&:hover': {
      // opacity: 0.8,
      // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: '#22447D!important',
    },
  },
});

export interface IDownloadLink {
  icon: string | React.ReactElement;
  isLeague: boolean;
  url: string;
  func: Function;
}

const DownloadDropdown: FC<IDownloadLink> = ({ icon, url, func, isLeague }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<1 | 2 | 3 | 4>(1);
  const [progress, setProgress] = useState<number>(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleExportPDFclick = () => {
    handleToggle();
    SuccessDownload();
    func();
  };
  const handleDownloadClick = () => {
    handleToggle();
    setProgress(0);
    setStatus(2);
    downloadFileFunc({
      isNewTab: true,
      progressFunc: setProgress,
      linkUrl: url,
      baseApiUrl: appConfig.api.core,
      thenFunc: SuccessDownload,
    });
  };
  const SuccessDownload = () => {
    setStatus(4);
    setTimeout(() => setStatus(1), 1800);
  };

  const options = [
    { name: 'Impact Report pdf', func: () => handleExportPDFclick() },
    isLeague
      ? null
      : {
          name: 'Benefit by Account',
          func: () => handleDownloadClick(),
        },
  ];

  useEffect(() => {
    if (status === 2 && progress > 1) {
      setStatus(3);
    }
  }, [status, progress]);

  const classes = useStyles();
  const States = {
    1: (
      <ButtonGroup
        variant="contained"
        color="secondary"
        aria-label="split button"
        style={{
          maxWidth: '212px',
          boxShadow: 'none',
          height: '48px',
          width: '212px',
        }}
      >
        <Button
          className={classes.cancelButton}
          style={{
            fontSize: '14px!important',
            width: '160px',
            padding: '16px 20px',
            borderRight: '1px solid #FFFFFF',
            color: '#2b39b9',
            display: 'flex',
            justifyContent: 'space-around',
          }}
          onClick={handleToggle}
        >
          <span
            style={{
              fontSize: '14px',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '12px' }}> {icon} </span>
            <span>Export</span>
          </span>{' '}
        </Button>
        <Button
          className={classes.cancelButton}
          style={{
            width: '52px',
          }}
          color="secondary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <KeyboardArrowDownIcon />
        </Button>
      </ButtonGroup>
    ),
    2: (
      <div
        className={`${s.button} ${s.button__loading}`}
        style={{
          background: '#52B36B',
          cursor: 'wait',
          justifyContent: 'center',
        }}
      >
        <span
          className={s.button__loading__text}
          style={{ justifyContent: 'center', margin: 0 }}
        >
          <span>Processing</span>
        </span>
      </div>
    ),
    3: (
      <div
        className={`${s.button} ${s.button__loading}`}
        style={{ background: '#52B36B', cursor: 'wait' }}
      >
        <span className={s.button__loading__text}>
          <span style={{ marginRight: '8px' }}>
            <DownloadIcon />
          </span>
          <span>Exporting may take time</span>
        </span>
        <div className={s.button__loader} style={{ width: `${progress}%` }} />
      </div>
    ),
    4: (
      <div className={`${s.button} ${s.button__done}`}>
        <span style={{ marginRight: '8px' }}>
          <CheckCircleComponent />
        </span>
        <span> Done </span>
      </div>
    ),
  };
  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition timeout={100} key={status} classNames="item">
          <>{States[status]}</>
        </CSSTransition>
      </SwitchTransition>
      <CSSTransition in={open} classNames="my-node" timeout={100} unmountOnExit>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={`${s.dropdown}`}>
            <>
              {options.map(
                (option) =>
                  option && (
                    <span
                      onClick={option.func}
                      key={option.name}
                      className={`${s.dropdown__item}`}
                    >
                      {option.name}
                    </span>
                  )
              )}
            </>
          </div>
        </ClickAwayListener>
      </CSSTransition>
    </>
  );
};

export default DownloadDropdown;
