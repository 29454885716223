/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import axios from 'axios';
import { appConfig } from '../../../constants';
import { toast } from '../../components/Toast/ToastManager';

export type DownloadFileFuncType = {
  isNewTab?: boolean;
  fileData?: string | React.ReactElement;
  linkUrl?: string;
  baseApiUrl?: string;
  thenFunc?: () => void;
  progressFunc?: (progress: number) => void;
};

const downloadFileFunc = ({
  isNewTab,
  fileData,
  linkUrl,
  baseApiUrl,
  thenFunc,
  progressFunc,
}: DownloadFileFuncType) => {
  const element = document.createElement('a');
  element.download = 'true';

  // Base API URL fallback logic
  const apiBaseUrl =
    baseApiUrl ||
    (appConfig.api.dataManagment === '/'
      ? window.location.origin
      : appConfig.api.dataManagment);

  if (fileData) {
    if (isNewTab) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.location.href = `${fileData}`;
      }
    } else {
      window.location.href = `${fileData}`;
    }
  } else {
    const fileLink = `${apiBaseUrl}${linkUrl}`;
    const stringToken = window.localStorage.getItem('token');
    axios
      .get(fileLink, {
        onDownloadProgress: ({ total, loaded }) => {
          /* Setting the progress of the download. */
          if (typeof progressFunc === 'function') {
            progressFunc(Math.round((loaded * 100) / total));
          }
        },
        headers: { Authorization: `Bearer ${stringToken}` },
        responseType: 'blob',
      })
      .then((response: any) => {
        const headerval = response.headers['content-disposition'];
        let filename = headerval.split(';')[1].split('=')[1].replace(/"/g, '');
        filename = decodeURI(filename);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
        if (typeof thenFunc === 'function') {
          thenFunc();
        }
      })
      .catch((error: any) => {
        if (typeof thenFunc === 'function') {
          toast.show({
            type: 'warring',
            title: 'Exporting Failed',
            content:
              'Unfortunately, the report generation is taking longer than expected. We are still processing it. Please refresh the page and try downloading again in 5 minutes.',
            duration: 8000,
          });
          thenFunc();
        }
      });
  }
};

export default downloadFileFunc;
